<template>
  <home></home>
</template>

<script setup>
import Home from "@/views/Home/Home.vue";

import { i18n } from "@/plugins/i18n.js";
useHead({
  title: () => i18n.global.tc("titles.home"),
});
</script>
