<template>
  <div class="banner">
    <div class="banner__inner">
      <div class="container">
        <div class="banner__content">
          <div class="banner__title">
            Запись на процедуру ещё никогда не была такой удобной!
          </div>

          <div
            ref="bannerFilter"
            class="banner__filter"
            v-click-outside="closeAll">
            <div class="banner__filter-inner">
              <div class="banner__filter-blocks">
                <div class="banner__filter-block service">
                  <div class="banner__filter-blockInner">
                    <span class="banner__filter-label Star"></span>
                    <input
                      :value="service.title"
                      type="text"
                      class="service-select"
                      placeholder="Эпиляция"
                      @focus="toggle('isServicesShown')" />
                  </div>

                </div>

                <div
                  class="banner__filter-block datatime"
                  :class="{ opened: isDateShown }">
                  <div class="banner__filter-blockInner">
                    <div class="filter-time__block">
                      <span class="banner__filter-label calendar"></span>
                      <input
                        :value="formatDateTime(date)"
                        type="text"
                        name="filter-time"
                        class="filter-time__input"
                        :placeholder="`Записаться сегодня на ${formattedDefaultTime}`"
                        readonly
                        @click="toggle('isDateShown')"
                      />
                    </div>
                  </div>

                </div>

                <div class="banner__filter-block salon">
                  <div class="banner__filter-blockInner">
                    <span class="banner__filter-label house"></span>
                    <input
                      :value="salon.title"
                      class="salon-select"
                      type="text"
                      placeholder="Любое заведение"
                      @focus="toggle('isSalonsShown')"
                    />
                  </div>
                </div>
              </div>
              <ServicesBlock
                v-if="isServicesShown"
                v-model="service"
                @close="closeAll" />
              <DateBlock
                v-if="isDateShown"
                v-model="date"
                @close="closeAll" />
              <SalonsBlock
                v-if="isSalonsShown"
                v-model="salon"
                @close="closeAll" />

              <div class="text-center banner__filter-footer">
                <router-link :to="searchRoute">
                  <button type="button" class="btn-grey banner__filter-btn">
                    Найти
                  </button>
                </router-link>
              </div>
            </div>
          </div>

          <div class="banner__filter mobile">
            <div class="banner__filter-inner">
              <div class="banner__filter-blocks">
                <div class="banner__filter-block">
                  <input
                    type="text"
                    name=""
                    class="banner__filter-input"
                    placeholder="Начать поиск" />
                </div>
              </div>
              <button type="button" class="banner__filter-btn"></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { formatDateTime } from "@/helpers/date";
</script>
<script>
import ServicesBlock from "./ServicesBlock.vue";
import DateBlock from "./DateBlock.vue";
import SalonsBlock from "./SalonsBlock.vue";

import ClickOutside from "vue-click-outside";

export default {
  name: "HomeBanner",
  components: {
    ServicesBlock,
    DateBlock,
    SalonsBlock,
  },

  directives: {
    ClickOutside,
  },

  data() {
    return {
      isServicesShown: false,
      isDateShown: false,
      isSalonsShown: false,

      service: { id: "", categoryId: "", title: "" },
      date: "",
      salon: { id: "", title: "" },
    };
  },

  computed: {
    defaultDate() {
      const date = new Date();
      const hours = date.getHours();
      date.setHours(hours + (date.getMinutes() > 0 ? 3 : 2));
      date.setMinutes(0);

      return date;
    },

    formattedDefaultTime() {
      const hours = String(this.defaultDate.getHours());
      return `${hours.padStart(2, "0")}:00`;
    },

    searchRoute() {
      return {
        path: "/search",
        query: {
          service: this.service.title || undefined,
          categoryId: this.service.categoryId || undefined,
          date: this.date
            ? this.date.toISOString()
            : this.defaultDate.toISOString(),
          salonTypeId: this.salon.id || undefined,
        },
      };
    },

    isTablet() {
      return window.innerWidth < 992;
    },
  },

  mounted() {
    this.popupItem = this.$refs.bannerFilter;
  },

  methods: {
    toggle(code) {
      if (typeof this[code] !== "undefined")
        this[code] = !this[code];
      this.bodyScroll(!this[code]);
    },
    closeAll() {
      this.isServicesShown = false;
      this.isDateShown = false;
      this.isSalonsShown = false;
      this.bodyScroll(true);
    },
    bodyScroll(status) {
      if (!status && this.isTablet) {
        document.body.classList.add("modal-open");
      } else {
        document.body.classList.remove("modal-open");
      }
    },
  },
};
</script>

<style lang="scss">
@import "_homeBanner";
</style>
