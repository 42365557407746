<template>
  <div class="banner-filter__dropdown banner-filter__dropdown--active banner-filter__datetimepicker datatime">
    <div class="banner-filter__dropdown-inner">
      <div class="banner-filter__dropdown-close" @click="close"></div>

      <DateTimePicker v-model="innerValue" />
    </div>
  </div>
</template>

<script>
import DateTimePicker from "@/components/Common/DateTimePicker.vue";

export default {
  name: "DateBlock",
  components: {
    DateTimePicker,
  },

  props: {
    modelValue: {
      type: [Date, String],
      required: true,
    },
  },

  computed: {
    innerValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-filter__dropdown-btn {
  top: 35px;
}
</style>
