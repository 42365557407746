<template>
  <div class="reviews-block" :class="{ short: isCollapsed }">
    <div class="reviews-block__inner">
      <div class="reviews-block__top">
        <div class="reviews-block__photo">
          <img :src="review.avatarSrc" alt="" />
        </div>

        <div class="reviews-block__top-content">
          <div class="reviews-block__name">{{ review.name }}</div>

          <div class="reviews-block__rating rating-block">
            <div class="rating-block__value">{{ review.rating }}</div>
          </div>
        </div>
      </div>

      <div class="reviews-block__salon">
        Отзыв на
        <router-link
          :to="`/${salon.slug || salon.id}`"
          class="text-underline"
        >
          {{ salon.name }}
        </router-link>

        <div class="reviews-block__salon-logo">
          <img :src="salon.logo" alt="" />
        </div>
      </div>

      <div class="reviews-block__footer">
        <div class="reviews-block__text">
          {{ review.text }}
        </div>

        <div
          v-if="review.text.length > 100"
          class="reviews-block__footer-buttons"
        >
          <span
            v-if="isCollapsed"
            class="reviews-block__footer-button"
            @click="isCollapsed = false"
          >
            Читать далее
          </span>

          <span
            v-else
            class="reviews-block__footer-button"
            @click="isCollapsed = true"
          >
            Скрыть
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReviewCard",

  props: {
    review: Object,
  },

  data() {
    return {
      isCollapsed: true,
    };
  },

  computed: {
    salon() {
      return this.review.salon;
    },
  },
};
</script>
