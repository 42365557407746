<template>
  <div class="reviews-blocks__inner container">
    <div class="our-slider__header">
      <h2 class="site-content__title">Отзывы</h2>

      <div class="reviews-blocks__header-right d-flex align-items-center">
        <div v-if="reviews.length" class="slider-nav">
          <div class="reviews-swiper-button-prev swiper-button-prev"></div>
          <div class="reviews-swiper-button-next swiper-button-next"></div>
        </div>
      </div>
    </div>

    <client-only v-if="isLoading">
      <SemipolarSpinner
        class="reviews__spinner"
        :animation-duration="2000"
        :size="50" />
    </client-only>

    <client-only v-if="reviews.length">
      <swiper
        v-bind="swiperOptions"
        :modules="swiperModules"
        class="reviews-blocks__slider swiper">
        <swiper-slide
          v-for="review in reviews"
          :key="review.id">
          <ReviewsCard
            class="swiper-slide"
            :review="review" />
        </swiper-slide>
      </swiper>
        <div class="reviews-swiper-button-next"></div>
        <div class="reviews-swiper-button-prev"></div>
    </client-only>

    <p v-else-if="!isLoading" class="reviews__stub">Пока нет отзывов</p>
  </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { SemipolarSpinner } from "epic-spinners";
import ReviewsCard from "../../ReviewsCard/ReviewsCard";

import { mainApi } from "@/stores/api/main-api";
import { Review } from "@/stores/models/Review";
const { $doNoty: doNoty } = useNuxtApp();
import { ref, reactive } from "vue";

const isLoading = ref(false);

async function loadData() {
  isLoading.value = true;
  try {
    const { data } = await mainApi.getReviews({ random: 1, limit: 15 });
    return data.data;
  } catch (err) {
    doNoty.error(err);
  } finally {
    isLoading.value = false;
  }
}
const { data } = await useAsyncData(async () => {
  const reviews = await loadData();
  return {
    reviews,
  };
});
const reviews = computed(() => (data.value.reviews || []).map((item) => new Review(item)));

const swiperModules = [Navigation];
const swiperOptions = reactive({
  slidesPerView: 3,
  spaceBetween: 30,
  shortSwipes: false,
  navigation: {
    clickable: true,
  },
  breakpoints: {
    0: { slidesPerView: 1, spaceBetween: 15 },
    767: { slidesPerView: 2 },
    992: { slidesPerView: 3 },
  },
});
</script>

<style lang="scss">
@import "reviews-slider";
</style>

<style lang="scss" scoped>
.reviews-blocks__slider .swiper-wrapper {
  align-items: flex-start;
}

.reviews {
  &__stub {
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 20px;
  }
}

.reviews__spinner {
  margin: 100px auto;
  display: grid;
  place-items: center;
}
</style>
