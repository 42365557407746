<template>
  <def-layout>
    <div class="main-content home-page">
      <HomeBanner />

      <OurPlus />

      <client-only v-if="isAuthorized">
        <section class="home-status__section home-page__section">
          <StatusHome />
        </section>

        <section class="last-visit__section home-page__section">
          <LastVisit />
        </section>
      </client-only>

      <template v-show="!isAuthorized">
        <section class="home-sale__section home-page__section">
          <SalesBanner />
        </section>
        <section  class="home-page__section">
          <Promosale />
        </section>
      </template>

      <section class="home-page__section">
        <HitService />
      </section>

      <section class="home-page__section">
        <ReviewsSlider />
      </section>
    </div>
  </def-layout>
</template>

<script setup>
import DefLayout from "@/layouts/Default/Default";
import bannerImage from "@/assets/img/banner.png";

import ReviewsSlider from "@/components/Sliders/ReviewsSlider/ReviewsSlider";
import HomeBanner from "@/components/HomeBanner/HomeBanner";
import OurPlus from "@/components/OurPlus/OurPlus";
import StatusHome from "@/components/StatusHome/StatusHome";
import LastVisit from "@/components/LastVisit/LastVisit";
import SalesBanner from "@/components/SalesBanner/SalesBanner";
import Promosale from "@/components/Promosale/Promosale";
import HitService from "@/components/HitService/HitService";

import { computed } from "vue";
const accountModule = useAccount();

const isAuthorized = computed(() => !!accountModule.userId);
</script>

<style lang="scss" scoped>
@import "home";
</style>
