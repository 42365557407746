<template>
  <div class="hit-section container">
    <div class="site-content__header d-flex align-items-center justify-content-between">
      <div class="site-content__title">Популярные услуги</div>
    </div>

    <div class="populat-service__blocks">
      <client-only v-if="isLoading">
        <SemipolarSpinner
          class="hit-service__spinner"
          :animation-duration="2000"
          :size="50" />
      </client-only>

      <template v-if="services.length">
        <div class="row">
          <div
            v-for="service in services"
            :key="service.id"
            class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
            <div class="populat-service__block">
              <div class="populat-service__block-inner">
                <div class="populat-service__block-header">
                  <div class="populat-service__block-icons">
                    <div
                      v-if="service.gender"
                      role="tooltip"
                      :data-microtip-position="'top'"
                      data-microtip-size="medium"
                      :aria-label="service.gender === 'Ж'
                        ? 'Подходит женщинам'
                        : service.gender === 'М'
                          ? 'Подходит мужчинам'
                          : 'Подходит всем'
                      "
                      class="populat-service__block-icon populat-service__block-gender">
                      <img v-if="service.gender === 'Ж'" src="@/assets/img/icon/female.svg" />
                      <img v-else-if="service.gender === 'М'" src="@/assets/img/icon/male.svg" />
                      <img v-else src="@/assets/img/icon/male-female.svg" />
                    </div>
                  </div>
                  <span
                    class="shop-favorite"
                    :class="{
                      active: isFavorite(service),
                    }"
                    @click="toggleFavorite(service)" />

                  <span
                    v-if="service.isBestPrice"
                    class="best-price-label"
                    role="tooltip"
                    data-microtip-position="top-right"
                    data-microtip-size="medium"
                    aria-label="Салон по лучшей цене" />
                  <router-link
                    :to="`/${service.salon.slug || service.salon.id}`"
                    class="populat-service__block-img">
                    <img :src="service.cover" />
                  </router-link>
                </div>
                <div class="populat-service__block-footer">
                  <div class="populat-service__raiting d-flex align-items-center justify-content-between">
                    <div class="populat-service__raiting-left d-flex align-items-center">
                      <span class="populat-service__raiting-value">{{
                        service.rating
                      }}</span>
                      <div class="populat-service__raiting-reviews">
                        {{ $tc("review.count", service.reviewsCount) }}
                      </div>
                    </div>

                    <div class="populat-service__raiting-right">
                      <span class="promo-time-procedure">
                        <span>{{ service.duration }} м</span>
                      </span>
                    </div>
                  </div>

                  <router-link
                    :to="{
                      path: '/search',
                      query: {
                        categoryId: service.specialistService.categoryId,
                      },
                    }"
                    class="populat-service__name">
                    {{ service.title }} ({{ service.salon.name }})
                  </router-link>

                  <div class="populat-service__prices d-flex">
                    <template v-if="service.priceWithDiscount">
                      <div class="populat-service__price new">
                        от {{ formatMoney(service.priceWithDiscount) }}
                      </div>
                      <div class="populat-service__price old">
                        от {{ formatMoney(service.price) }}
                      </div>
                    </template>
                    <div v-else class="populat-service__price new">
                      от {{ formatMoney(service.price) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="populat-service__blocks-footer text-center">
          <router-link
            :to="{ path: '/services' }"
            class="btn btn-border btn-see-all">
            Смотреть все
          </router-link>
        </div>
      </template>

      <p v-else-if="!isLoading" class="hit-service__stub">Пока нет услуг</p>
    </div>
  </div>
</template>

<script setup>
import { SemipolarSpinner } from "epic-spinners";
import { SearchService } from "@/stores/models/SearchService";
import { mainApi } from "@/stores/api/main-api";
const { $doNoty: doNoty } = useNuxtApp();
import { formatMoney } from "@/helpers/formatters";

import { ref } from "vue";

const isLoading = ref(false);

const searchStore = useSearch();
const favoritesModule = useFavorites();

function getSalonType(service) {
  const type =
    searchStore.salonTypes.find((item) => item.id === service.salon.salonTypeId) ||
    {};

  return type.title || "";
}

async function loadData() {
  try {
    isLoading.value = true;
    if (!searchStore.salonTypes.length) {
      await searchStore.getSalonTypes();
    }

    const { data } = await mainApi.searchServices({
      random: 1,
      limit: 4,
    });
    const { data: stats } = await mainApi.getReviewsStatistics({
      salon_service_id: data.services.map((item) => item.id),
    });

    isLoading.value = false;
    return data.services.map(
      (item) =>
        new SearchService({
          ...(stats.find((s) => s.id === item.id)?.statistic || {}),
          ...item,
        })
    );
  } catch (err) {
    doNoty.error(err);
    isLoading.value = false;
    return [];
  }
}

function toggleFavorite(service) {
  const favoritesModule = useFavorites();
  favoritesModule.setFavoriteService({
    serviceId: service.specialistService.id,
    specialistId: service.specialistService.specialistId,
    isFavorite: !isFavorite(service),
  });
}

function isFavorite(service) {
  const specialist = favoritesModule.favoriteServices.find(
    (item) => item.specialistId === service.specialistService.specialistId
  );

  if (!specialist) return false;

  return specialist.items.includes(service.specialistService.id);
}
const { data } = await useAsyncData(async () => {
  const services = await loadData();
  return {
    services,
  };
});

const services = computed(() => data.value.services || []);
</script>

<style lang="scss" scoped>
@import "hitService";

.populat-service__block-header {
  .best-price-label {
    position: absolute;
  }
}

.populat-service__desc {
  display: block;
  color: inherit;
  text-decoration: none;
  margin-bottom: 1rem;
}

.hit-service {
  &__stub {
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 20px;
    margin: 100px auto;
  }

  &__spinner {
    margin: 100px auto;
    display: grid;
    place-items: center;
  }
}
</style>
