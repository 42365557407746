<template>
  <div class="status-home">
    <div class="container">
      <div class="site-content__header d-flex align-items-center justify-content-between">
        <div class="site-content__title">Статус</div>
      </div>

      <div class="status-home__blocks d-flex">
        <div class="status-home__block">
          <div
            class="status-home__block-inner"
            :style="{ background: `url(${currentStatusBg}) no-repeat` }">
            <div class="status-home__block-header d-flex align-items-center">
              <div class="status-home__block-number">
                {{ currentStatusIndex + 1 }}
              </div>

              <div class="status-home__block-name">
                {{ $t(`statuses.${currentStatus.title}`) }}
              </div>
            </div>

            <div class="status-home__block-content">
              <div class="status-home__block-title">
                <strong>Ваш уровень:</strong>
                {{ $t(`statuses.${currentStatus.title}`) }}
              </div>

              <div class="status-home__block-text">
                По результатам потраченной суммы:
                {{ formatMoney(spentAmount) }}
              </div>
            </div>

            <div class="status-home__block-footer d-flex align-items-center">
              <router-link
                :to="{ path: '/user/status', hash: '#status' }"
                class="btn btn-white">
                Подробнее
              </router-link>

              <div class="status-home__block-info">
                До следующего уровня {{ formatMoney(nextStatus.amount - spentAmount) }}
              </div>
            </div>
          </div>
        </div>

        <div v-if="nextStatus.title" class="status-home__block">
          <div
            class="status-home__block-inner"
            :style="{ background: `url(${nextStatusBg}) no-repeat` }">
            <div class="status-home__block-header d-flex align-items-center">
              <div class="status-home__block-number">
                {{ currentStatusIndex + 2 }}
              </div>

              <div class="status-home__block-name">
                {{ $t(`statuses.${nextStatus.title}`) }}
              </div>
            </div>

            <div class="status-home__block-content">
              <div class="status-home__block-title">
                <strong>Кэшбек: {{ nextStatus.cashback * 100 }}%</strong>
              </div>
              <div class="status-home__block-text">
                Тратьте больше и получайте большие проценты
              </div>
            </div>

            <div class="status-home__block-footer d-flex align-items-center">
              <router-link
                :to="{ path: '/user/status', hash: '#features' }"
                class="btn btn-white">
                Подробнее
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { formatMoney } from "@/helpers/formatters";
import { STATUS_IMAGES_MAP } from "@/constants/user-statuses";
import { computed } from "vue";

const accountModule = useAccount();

const currentStatus = computed(() => accountModule.currentCashbackLevel);
const nextStatus = computed(() => accountModule.nextCashbackLevel);
const spentAmount = computed(() => accountModule.spentAmount);
const currentStatusIndex = computed(
  () => accountModule.cashbackLevels.indexOf(accountModule.currentCashbackLevel)
);
const currentStatusBg = computed(() => STATUS_IMAGES_MAP[accountModule.currentCashbackLevel?.title]);
const nextStatusBg = computed(() => STATUS_IMAGES_MAP[accountModule.nextCashbackLevel?.title]);
</script>

<style lang="scss">
@import "status-home";
</style>
