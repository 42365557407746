<template>
  <div class="banner-filter__dropdown banner-filter__dropdown--active salon">
    <div class="banner-filter__dropdown-inner">
      <div class="banner-filter__dropdown-title">Выберите заведение</div>
      <div class="banner-filter__dropdown-close" @click="close"></div>

      <div class="banner-filter__dropdown-search position-relative">
        <span class="banner__filter-label house"></span>
        <input
          ref="houseFilters"
          v-model.trim="search"
          class="banner-filter__dropdown-input service"
          type="text"
          placeholder="Любое заведение"
          @keydown.esc="close"
        />
        <button class="bth banner-filter__dropdown-btn" @click="close"></button>
      </div>

      <div class="banner-filter__salon-location">
        <div class="banner-filter__salon-locationInner">
          Места поблизости от 0 до 10 метров
        </div>
      </div>

      <div class="banner-filter__dropdown-content">
        <div class="banner-filter__dropdown-block second">
          <div class="banner-filter__dropdown-items">
            <div
              v-for="salonType in filteredSalonTypes"
              :key="salonType.id"
              class="banner-filter__dropdown-item"
              @click="selectSalonType(salonType)"
            >
              <span>{{ salonType.title }}</span>
            </div>
          </div>
        </div>

        <div class="banner-filter__dropdown-block first">
          <div class="first-block__items">
            <div
              class="first-block__item"
              @click="selectSalonType({ id: '', title: '' })"
            >
              <label class="custom-input__label">
                <input
                  class="custom-input__input"
                  type="radio"
                  name="services_type"
                  :checked="modelValue.id === ''"
                />
                <span class="custom-input__text">Все заведения</span>
              </label>
            </div>

            <div
              v-for="salonType in searchStore.salonTypes"
              :key="salonType.id"
              class="first-block__item"
              @click="selectSalonType(salonType)"
            >
              <label class="custom-input__label">
                <input
                  class="custom-input__input"
                  type="radio"
                  name="services_type"
                  :checked="modelValue.id === salonType.id"
                />
                <span class="custom-input__text">{{ salonType.title }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

export default {
  name: "SalonsBlock",

  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      search: "",
    };
  },

  computed: {
    searchStore() {
      return useSearch();
    },

    filteredSalonTypes() {
      const search = this.search.toLowerCase();
      if (search.length < 3) return [];

      return this.searchStore.salonTypes
        .filter((item) => item.title.toLowerCase().includes(search))
        .slice(0, 4);
    },
  },

  async created() {
    if (this.searchStore.salonTypes.length > 0) return;
    await this.searchStore.getSalonTypes();
  },

  mounted() {
    this.$refs.houseFilters.focus();
  },

  methods: {
    close() {
      this.$emit("close");
    },

    selectSalonType(salonType) {
      this.$emit("update:modelValue", {
        id: salonType.id,
        title: salonType.title,
      });
      this.close();
    },
  },
};
</script>
