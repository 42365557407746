<template>
  <div class="banner-filter__dropdown banner-filter__dropdown--active">
    <div class="banner-filter__dropdown-inner">
      <div class="banner-filter__dropdown-title">Выберите услугу</div>
      <div class="banner-filter__dropdown-close" @click="close"></div>

      <div class="banner-filter__dropdown-search position-relative">
        <span class="banner__filter-label Star"></span>
        <input
          ref="filters"
          v-model.trim="search"
          class="banner-filter__dropdown-input service"
          type="text"
          placeholder="Искать в Webberu"
          @keydown.esc="close"
          @input="searchServices"
        />
        <button class="bth banner-filter__dropdown-btn" @click="close"></button>
      </div>

      <div class="banner-filter__dropdown-content">
        <div
          v-if="search.length < 3"
          class="banner-filter__dropdown-block first"
        >
          <div class="first-blocks">
            <div class="row">
              <div class="col-xl-7">
                <div class="first-block last-search">
                  <div class="first-block__title">Вы недавно искали</div>
                  <div class="first-block__items">
                    <div
                      v-for="service in recentSearches"
                      :key="service.id"
                      class="first-block__item"
                      @click.stop="selectService(service)"
                    >
                      <div class="first-block__item-name">
                        {{ service.title }}
                      </div>

                      <div
                        class="first-block__item-remove"
                        @click.stop="removeRecentSearch(service)"
                      />
                    </div>

                    <p
                      v-if="recentSearches.length === 0"
                      class="first-block__text"
                    >
                      У вас ещё не было запросов
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-xl-5">
                <div class="first-block">
                  <div class="first-block__title">Категории</div>
                  <div class="first-block__tegs">
                    <div
                      class="first-block__teg"
                      v-for="category in filteredCategories"
                      :key="category.id"
                      @click="selectCategory(category)"
                    >
                      <div class="first-block__teg-name">
                        <span class="first-block__teg-icon">
                          <img :src="category.image" />
                        </span>

                        {{ category.title }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="banner-filter__dropdown-block second" v-else>
          <div v-if="isLoading" class="banner-filter__dropdown-loader">
            <SemipolarSpinner
              class="banner-filter__dropdown-loader-spinner"
              :animation-duration="2000"
              :size="50"
            />
          </div>
          <div
            v-else-if="searchStore.services.length"
            class="banner-filter__dropdown-items custom-scroll"
          >
            <div
              class="banner-filter__dropdown-item"
              v-for="service in searchStore.services"
              :key="service.id"
              @click="selectService(service)"
            >
              <span v-if="service.categoryIcon" class="banner-filter__dropdown-itemIcon">
                <img :src="service.categoryIcon" />
              </span>

              <mark>{{ service.title }}</mark>
            </div>
          </div>

          <div v-else class="banner-filter__dropdown-block first">
            <div class="first-block__title">Результатов не найдено</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SemipolarSpinner } from "epic-spinners";
import { createNamespacedHelpers } from "vuex";
import debounce from "lodash/debounce";

const RECENT_SEARCHES_STORAGE_KEY = "recentSearches";

export default {
  name: "ServicesBlock",
  components: { SemipolarSpinner },

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      search: "",
      isLoading: false,
      recentSearches: [],
      searchDebounced: () => {},
    };
  },

  computed: {
    searchStore() {
      return useSearch();
    },

    filteredCategories() {
      return this.searchStore.serviceCategories
        .filter((item) => item.showInSearch)
        .sort((a, b) => a.sortInSearch - b.sortInSearch);
    },
  },

  async created() {
    this.searchDebounced = debounce(async () => {
      await this.searchStore.getServices(this.search);
      this.isLoading = false;
    }, 500);

    this.loadRecentSearches();
  },

  mounted() {
    this.$refs.filters.focus();
  },

  methods: {
    close() {
      this.$emit("close");
    },

    searchServices() {
      if (this.search.length < 3) return;

      this.isLoading = true;
      this.searchDebounced();
    },

    selectService(service) {
      this.$emit("input", {
        id: service.id,
        categoryId: service.category_id,
        title: service.title,
      });
      this.updateRecentSearches(service);
      this.close();
    },

    selectCategory(category) {
      this.$emit("input", {
        id: "",
        categoryId: category.id,
        title: category.title,
      });
      this.close();
    },

    loadRecentSearches() {
      const storageSearches = localStorage.getItem(RECENT_SEARCHES_STORAGE_KEY);
      if (!storageSearches) return;

      try {
        this.recentSearches = JSON.parse(storageSearches);
      } catch (e) {
        console.error(e);
      }
    },

    updateRecentSearches(service) {
      const index = this.recentSearches.findIndex(
        (item) => item.id === service.id
      );
      if (index !== -1) {
        this.recentSearches.splice(index, 1);
      }

      this.recentSearches = [service, ...this.recentSearches].slice(0, 4);
      this.saveRecentSearches();
    },

    removeRecentSearch(service) {
      const index = this.recentSearches.indexOf(service);
      this.recentSearches.splice(index, 1);
      this.saveRecentSearches();
    },

    saveRecentSearches() {
      localStorage.setItem(
        RECENT_SEARCHES_STORAGE_KEY,
        JSON.stringify(this.recentSearches)
      );
    },
  },
};
</script>
