<template>
  <div>
    <div class="status-banner__home">
      <div class="container">
        <div class="status-banner__inner">
          <div class="row align-items-center justify-content-center">
            <div class="col-xl-4 col-lg-5 col-md-6 order-xl-1 order-lg-1 order-md-1 order-sm-2 order-2 me-xl-5">
              <div class="status-banner__content">
                <p class="status-banner__title">
                  Получайте бонусы и экономьте на заказах!
                </p>
                <p class="status-banner__text">
                  И нет сомнений, что акционеры крупнейших компаний объявлены
                  нарушающими общечеловеческие нормы этики и морали.
                </p>
                <div class="status-banner__content-footer">
                  <a href="#" class="status-banner__link btn btn-grey d-block"
                    >Акции</a
                  >
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-5 col-md-6 order-xl-2 order-lg-2 order-md-2 order-sm-1 order-1">
              <div class="status-banner__image">
                <img src="@/assets/img/status/status-banner__img.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="!isDecideBannerHidden || !isRegisterBannerHidden"
      class="home-actions">
      <div class="container">
        <div class="home-actions__blocks">
          <div class="row">
            <div
              v-if="!isDecideBannerHidden"
              class="col-xl-6 col-lg-6 col-md-12">
              <div class="home-actions__block">
                <div class="home-actions__block-inner bg-1">
                  <div class="home-actions__block-content">
                    <div class="home-actions__block-header">
                      <p class="home-actions__block-title">Решай сам</p>
                      <p class="home-actions__block-text">
                        Подключи услуги и решайте сами сколько вы готовы
                        заплатить
                      </p>
                    </div>
                    <div class="home-actions__block-footer d-flex align-items-center">
                      <router-link
                        to="/user/internal-account"
                        class="btn btn-white btn-add-service">
                        Подключить
                      </router-link>

                      <button
                        type="button"
                        class="btn btn-transform btn-hide-service"
                        @click="hideDecideBanner">
                        Показать позже
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="!isRegisterBannerHidden"
              class="col-xl-6 col-lg-6 col-md-12">
              <div class="home-actions__block">
                <div class="home-actions__block-inner bg-2">
                  <div class="home-actions__block-content">
                    <div class="home-actions__block-header">
                      <p class="home-actions__block-title">
                        Зарегистрируйте свой обьект
                      </p>

                      <p class="home-actions__block-text">
                        Начинайте зарабатывать уже сейчас
                      </p>
                    </div>
                    <div class="home-actions__block-footer d-flex align-items-center">
                      <router-link
                        :to="{ path: '/business' }"
                        class="btn btn-white btn-add-service">
                        Подключить
                      </router-link>
                      <button
                        type="button"
                        class="btn btn-transform btn-hide-service"
                        @click="hideRegisterBanner">
                        Показать позже
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookie from "js-cookie";

export default {
  name: "SalesBanner",

  data() {
    return {
      isDecideBannerHidden: Cookie.get("isDecideBannerHidden") === "true",
      isRegisterBannerHidden: Cookie.get("isRegisterBannerHidden") === "true",
    };
  },

  methods: {
    hideDecideBanner() {
      this.isDecideBannerHidden = true;
      Cookie.set("isDecideBannerHidden", "true", { expires: 7 });
    },

    hideRegisterBanner() {
      this.isRegisterBannerHidden = true;
      Cookie.set("isRegisterBannerHidden", "true", { expires: 7 });
    },
  },
};
</script>

<style lang="scss">
@import "salesBanner";
</style>
