<template>
  <div class="promo-section container">
    <div
      class="
        site-content__header
        d-flex
        align-items-center
        justify-content-between
      "
    >
      <div class="site-content__title">Промоакции</div>
    </div>

    <div class="promosale__blocks-wrp">
      <div class="promosale__blocks">
        <div class="row">
          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
            <div class="promosale__slide">
              <div class="promosale__slide-inner">
                <div class="promosale__slide-header">
                  <span
                    class="best-price-label"
                    role="tooltip"
                    data-microtip-position="top-right"
                    data-microtip-size="medium"
                    aria-label="Салон по лучшей цене"
                  />
                  <span class="shop-favorite"></span>
                  <img src="@/assets/img/promosale/promo_new1.png" alt="" />
                  <div class="promosale__slide-content">
                    <div class="promosale__slide-title">
                      Успей на
                      <span class="text-orange big text-uppercase"
                        >огромные</span
                      >
                      <span class="big">скидки</span>
                    </div>
                    <div class="promosale__slide-data">
                      Только с
                      <span>11.05.2021</span>
                    </div>
                  </div>
                </div>
                <div class="promosale__slide-footer">
                  <div
                    class="
                      promosale__slide-raiting
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    <div
                      class="
                        promosale__slide-raiting-left
                        d-flex
                        align-items-center
                      "
                    >
                      <span class="promosale__slide-raitingValue">4.2</span>
                      <div class="promosale__slide-raitingReviews">
                        124 отзыва
                      </div>
                    </div>
                    <div class="promosale__slide-raiting-right">
                      <span class="promo-time-procedure">
                        <span>1 ч 30 м</span>
                      </span>
                    </div>
                  </div>
                  <a href="#" class="promosale__slide-name"
                    >Перманентный макияж в студии красоты “Эвелин”</a
                  >
                  <div class="promosale__slide-prices d-flex">
                    <div class="promosale__slide-price new">от 1200 руб.</div>
                    <div v-if="false" class="promosale__slide-price old">
                      от 1200руб.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
            <div class="promosale__slide">
              <div class="promosale__slide-inner">
                <div class="promosale__slide-header">
                  <span class="shop-favorite"></span>
                  <img src="@/assets/img/promosale/promo_new2.png" alt="" />
                  <div class="promosale__slide-content">
                    <div class="promosale__slide-title">
                      Успей на
                      <span class="text-orange big text-uppercase"
                        >огромные</span
                      >
                      <span class="big">скидки</span>
                    </div>
                    <div class="promosale__slide-data">
                      Только с
                      <span>11.05.2021</span>
                    </div>
                  </div>
                </div>
                <div class="promosale__slide-footer">
                  <div
                    class="
                      promosale__slide-raiting
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    <div
                      class="
                        promosale__slide-raiting-left
                        d-flex
                        align-items-center
                      "
                    >
                      <span class="promosale__slide-raitingValue">4.2</span>
                      <div class="promosale__slide-raitingReviews">
                        124 отзыва
                      </div>
                    </div>
                    <div class="promosale__slide-raiting-right">
                      <span class="promo-time-procedure">
                        <span>1 ч 30 м</span>
                      </span>
                    </div>
                  </div>
                  <a href="#" class="promosale__slide-name"
                    >Перманентный макияж в студии красоты “Эвелин”</a
                  >
                  <div class="promosale__slide-prices d-flex">
                    <div class="promosale__slide-price new">от 1200 руб.</div>
                    <div v-if="false" class="promosale__slide-price old">
                      от 1200руб.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
            <div class="promosale__slide">
              <div class="promosale__slide-inner">
                <div class="promosale__slide-header">
                  <span class="shop-favorite"></span>
                  <img src="@/assets/img/promosale/promo_new1.png" alt="" />
                  <div class="promosale__slide-content">
                    <div class="promosale__slide-title">
                      Успей на
                      <span class="text-orange big text-uppercase"
                        >огромные</span
                      >
                      <span class="big">скидки</span>
                    </div>
                    <div class="promosale__slide-data">
                      Только с
                      <span>11.05.2021</span>
                    </div>
                  </div>
                </div>
                <div class="promosale__slide-footer">
                  <div
                    class="
                      promosale__slide-raiting
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    <div
                      class="
                        promosale__slide-raiting-left
                        d-flex
                        align-items-center
                      "
                    >
                      <span class="promosale__slide-raitingValue">4.2</span>
                      <div class="promosale__slide-raitingReviews">
                        124 отзыва
                      </div>
                    </div>
                    <div class="promosale__slide-raiting-right">
                      <span class="promo-time-procedure">
                        <span>1 ч 30 м</span>
                      </span>
                    </div>
                  </div>
                  <a href="#" class="promosale__slide-name"
                    >Перманентный макияж в студии красоты “Эвелин”</a
                  >
                  <div class="promosale__slide-prices d-flex">
                    <div class="promosale__slide-price new">от 1200 руб.</div>
                    <div v-if="false" class="promosale__slide-price old">
                      от 1200руб.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
            <div class="promosale__slide">
              <div class="promosale__slide-inner">
                <div class="promosale__slide-header">
                  <span class="shop-favorite"></span>
                  <img src="@/assets/img/promosale/promo_new2.png" alt="" />
                  <div class="promosale__slide-content">
                    <div class="promosale__slide-title">
                      Успей на
                      <span class="text-orange big text-uppercase"
                        >огромные</span
                      >
                      <span class="big">скидки</span>
                    </div>
                    <div class="promosale__slide-data">
                      Только с
                      <span>11.05.2021</span>
                    </div>
                  </div>
                </div>
                <div class="promosale__slide-footer">
                  <div
                    class="
                      promosale__slide-raiting
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    <div
                      class="
                        promosale__slide-raiting-left
                        d-flex
                        align-items-center
                      "
                    >
                      <span class="promosale__slide-raitingValue">4.2</span>
                      <div class="promosale__slide-raitingReviews">
                        124 отзыва
                      </div>
                    </div>
                    <div class="promosale__slide-raiting-right">
                      <span class="promo-time-procedure">
                        <span>1 ч 30 м</span>
                      </span>
                    </div>
                  </div>
                  <a href="#" class="promosale__slide-name"
                    >Перманентный макияж в студии красоты “Эвелин”</a
                  >
                  <div class="promosale__slide-prices d-flex">
                    <div class="promosale__slide-price new">от 1200 руб.</div>
                    <div v-if="false" class="promosale__slide-price old">
                      от 1200руб.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="promosale__blocks-footer text-center">
          <a href="#" class="btn btn-border btn-see-all">Смотреть все</a>
        </div>
      </div>

      <p class="promosale__blocks-stub">Функционал в разработке</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "Promosale",
};
</script>
<style lang="scss">
@import "promosale";

.promosale__slide-header {
  .best-price-label {
    position: absolute;
  }
}

.promosale__blocks-wrp {
  position: relative;
}

.promosale__blocks-stub {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $white;
  padding: 20px;
  border-radius: 11px;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.13);
}

.promosale__blocks {
  opacity: 0.2;
  touch-action: none;
  filter: blur(5px);
}
</style>
