<template>
  <div class="last-visit">
    <div class="container">
      <div class="site-content__header d-flex align-items-center justify-content-between">
        <div class="site-content__title">Оставьте отзыв</div>
      </div>

      <template v-if="isLoading">
        <SemipolarSpinner
          class="orders__spinner"
          :animation-duration="2000"
          :size="50" />
      </template>

      <template v-else>
        <div class="last-visit__header">
          <div class="last-visit__header-text">
            <template v-if="!filteredOrders.length">
              Вы ещё ничего не заказывали
            </template>

            <template v-else> Вы недавно посещали: </template>
          </div>

          <div v-if="filteredOrders.length" class="last-visit__header-salons">
            {{ filteredOrders.map((item) => item.salon.name).join(", ") }}
          </div>
        </div>

        <div class="last-visit__blocks">
          <div class="row">
            <div
              v-for="order in filteredOrders"
              :key="order.id"
              class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
              <div class="last-visit__block">
                <div class="last-visit__block-inner">
                  <router-link
                    :to="`/${order.salon.slug || order.salon.id}`"
                    class="last-visit__block-inner">
                    <div class="last-visit__block-img">
                      <img v-if="order.salon.images[0]" :src="order.salon.images[0]" />
                      <img v-else src="@/assets/img/no-image.png" alt="" />
                    </div>

                    <div class="last-visit__block-content">
                      <div class="last-visit__block-name">
                        {{ order.salon.name }}
                      </div>

                      <div class="last-visit__block-service">
                        {{ order.specialistService.serviceTitle }}
                      </div>
                    </div>
                  </router-link>

                  <div class="last-visit__footer">
                    <component
                      :is="getReview(order).id ? 'router-link' : 'div'"
                      :to="{
                        path: '/user/reviews',
                        query: { id: getReview(order).id },
                      }">
                      <button
                        type="button"
                        class="bth btn-border last-visit__btn"
                        @click.stop="addReview(order)">
                        <template v-if="getReview(order).id">
                          Перейти к отзыву
                        </template>

                        <template v-else> Оставить отзыв </template>
                      </button>
                    </component>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { SemipolarSpinner } from "epic-spinners";
import { mainApi } from "@/stores/api/main-api";
import { Order } from "@/stores/models/Order";
import uniqBy from "lodash/uniqBy";
import { Review } from "@/stores/models/Review";

export default {
  name: "LastVisit",
  components: {
    SemipolarSpinner,
  },

  data() {
    return {
      orders: [],
      isLoading: false,
    };
  },

  computed: {
    filteredOrders() {
      return uniqBy(this.orders, "salon.id");
    },
  },

  created() {
    if (process.client) {
      this.loadOrders();
      this.$eventHub.$on("add-review", this.loadOrders);
    }
  },

  beforeDestroy() {
    this.$eventHub.$off("add-review", this.loadOrders);
  },

  methods: {
    async loadOrders() {
      const accountModule = useAccount();
      this.isLoading = true;
      try {
        const { data: orders } = await mainApi.getOrders({
          user_id: accountModule.userId,
        });
        this.orders = orders.data.map((item) => new Order(item)).slice(0, 4);

        const { data: reviews } = await mainApi.getReviews({
          user_id: accountModule.userId,
          salon_id: this.filteredOrders.map((item) => item.salonId),
        });
        this.reviews = reviews.data.map((item) => new Review(item));
      } catch (err) {
        const { $doNoty: doNoty } = useNuxtApp();
        doNoty.error(err);
      } finally {
        this.isLoading = false;
      }
    },

    addReview(order) {
      const reviewModule = useReviewModule();
      reviewModule.setOrder(order);
      this.$bvModal.show("review-modal");
    },

    getReview(order) {
      return this.reviews.find((item) => item.salon.id === order.salonId) || {};
    },
  },
};
</script>

<style lang="scss">
@import "last-visit";

.orders__spinner {
  margin: 100px auto;
  display: grid;
  place-items: center;
}
</style>
