<template>
  <div class="our-plus">
    <div class="container">
      <div class="our-plus__blocks d-flex">
        <div class="our-plus__block">
          <div class="our-plus__icon">
            <img src="@/assets/img/icon/our-pluses1.svg" alt="" />
          </div>
          <div class="our-plus__text">Система бронирования</div>
        </div>
        <div class="our-plus__block">
          <div class="our-plus__icon">
            <img src="@/assets/img/icon/our-pluses2.svg" alt="" />
          </div>
          <div class="our-plus__text">Бесплатное использование</div>
        </div>
        <div class="our-plus__block">
          <div class="our-plus__icon">
            <img src="@/assets/img/icon/our-pluses3.svg" alt="" />
          </div>
          <div class="our-plus__text">Мастера онлайн</div>
        </div>
        <div class="our-plus__block">
          <div class="our-plus__icon">
            <img src="@/assets/img/icon/our-pluses4.svg" alt="" />
          </div>
          <div class="our-plus__text">Салоны рядом</div>
        </div>
        <div class="our-plus__block">
          <div class="our-plus__icon">
            <img src="@/assets/img/icon/our-pluses5.svg" alt="" />
          </div>
          <div class="our-plus__text">Независимый рейтинг</div>
        </div>
        <div class="our-plus__block">
          <div class="our-plus__icon">
            <img src="@/assets/img/icon/our-pluses6.svg" alt="" />
          </div>
          <div class="our-plus__text">“Хочу скидку”</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "OurPlus",
};
</script>
<style lang="scss">
@import "ourplus";
</style>
